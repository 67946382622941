import { answerService } from '../services'

export const answers = {
  namespaced: true,
  state: {
    byQuestion: {}
  },
  actions: {
    getByQuestionId ({ commit }, questionId) {
      commit('getByQuestionIdRequest', { questionId })
      answerService.getAll(questionId).then(
        answers => commit('getByQuestionIdSuccess', { questionId, answers }),
        error => commit('getByQuestionIdError', { questionId, error })
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      const questionId = payload.questionId
      answerService.create(questionId, payload).then(
        answer => {
          commit('createSuccess', { questionId, answer })
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      answerService.update(payload.questionId, payload).then(
        answer => {
          commit('updateSuccess', answer)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    delete ({ dispatch, commit }, payload) {
      commit('deleteRequest', payload)
      const questionId = payload.questionId
      answerService.delete(payload.questionId, payload.questionAnswerId).then(
        payload => {
          commit('deleteSuccess', payload)
          dispatch('getByQuestionId', questionId)
        },
        error => {
          commit('deleteFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getByQuestionIdRequest (state, payload) {
      if (!state.byQuestion[payload.questionId]) {
        state.byQuestion[payload.questionId] = []
      }
    },
    getByQuestionIdSuccess (state, payload) {
      for (const newAnswer of payload.answers) {
        let answerExists = false
        for (const existingAnswer of state.byQuestion[payload.questionId]) {
          if (existingAnswer.id === newAnswer.id) {
            answerExists = true
            break
          }
        }
        if (!answerExists) {
          state.byQuestion[payload.questionId].push(newAnswer)
        }
      }
      const answerIndexesToDelete = []
      for (const existingAnswer of state.byQuestion[payload.questionId]) {
        let answerExists = false
        for (const newAnswer of payload.answers) {
          if (existingAnswer.id === newAnswer.id) {
            answerExists = true
            break
          }
        }
      }
      for (const answerIndexToDelete of answerIndexesToDelete) {
        state.byQuestion[payload.questionId].splice(answerIndexToDelete, 1)
      }
    },
    getByQuestionIdFailure (state, payload) {
      if (!state.byQuestion[payload.questionId]) {
        state.byQuestion[payload.questionId] = []
      }
    },
    createRequest (state) {},
    createSuccess (state, file) {},
    createFailure (state, error) {},
    updateRequest (state, data) {},
    updateSuccess (state, file) {},
    updateFailure (state, error) {},
    deleteRequest (state, questionId, questionAnswerId) {},
    deleteSuccess (state, questionId, questionAnswerId) {},
    deleteFailure (state, error) {}
  }
}
