import { faqSectionService } from '../services'

export const faqSections = {
  namespaced: true,
  state: {
    byFaq: {}
  },
  actions: {
    getByFaqId ({ commit }, faqId) {
      commit('getByFaqIdRequest', { faqId })
      faqSectionService.getByFaqId(faqId).then(
        sections => commit('getByFaqIdSuccess', { faqId, sections }),
        error => commit('getByFaqIdFailure', { faqId, error })
      )
    },
    getById ({ commit }, id) {
      commit('getByIdRequest')
      faqSectionService.getById(id).then(
        section => commit('getByIdSuccess', section),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      faqSectionService.create(payload.faqId, payload).then(
        section => {
          commit('createSuccess', section)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      faqSectionService.update(payload.faqId, payload).then(
        section => {
          commit('updateSuccess', section)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    delete ({ dispatch, commit }, payload) {
      commit('deleteRequest', payload)
      const faqId = payload.faqId
      faqSectionService.delete(payload.faqId, payload.faqSectionId).then(
        payload => {
          commit('deleteSuccess', payload)
          dispatch('getByFaqId', faqId)
        },
        error => {
          commit('deleteFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getByFaqIdRequest (state, payload) {
      if (!state.byFaq[payload.faqId]) {
        state.byFaq[payload.faqId] = []
      }
    },
    getByFaqIdSuccess (state, payload) {
      for (const newSection of payload.sections) {
        let sectionExists = false
        for (const existingSection of state.byFaq[payload.faqId]) {
          if (existingSection.id === newSection.id) {
            sectionExists = true
            break
          }
        }
        if (!sectionExists) {
          state.byFaq[payload.faqId].push(newSection)
        }
      }
      const sectionIndexesToDelete = []
      for (const existingSection of state.byFaq[payload.faqId]) {
        let sectionExists = false
        for (const newSection of payload.sections) {
          if (existingSection.id === newSection.id) {
            sectionExists = true
            break
          }
        }
      }
      for (const sectionIndexToDelete of sectionIndexesToDelete) {
        state.byFaq[payload.faqId].splice(sectionIndexToDelete, 1)
      }
    },
    getByFaqIdFailure (state, payload) {
      if (!state.byFaq[payload.faqId]) {
        state.byFaq[payload.faqId] = []
      }
    },
    getByIdRequest (state, formData) {},
    getByIdSuccess (state, section) {
      state.all = { section: section }
    },
    getByIdFailure (state, error) {},
    createRequest (state, formData) {},
    createSuccess (state, file) {},
    createFailure (state, error) {},
    updateRequest (state, data) {},
    updateSuccess (state, file) {},
    updateFailure (state, error) {},
    deleteRequest (state, orderId, itemId) {},
    deleteSuccess (state, orderId, itemId) {},
    deleteFailure (state, error) {},
  }
}
