import config from 'config'
import { ApiObjectService } from './api-object.service'
import { authHeader } from '../helpers'

class QuizService extends ApiObjectService {
    namespace = 'quizes'

    saveQuestionImage (questionId, dataUrl) {
      const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ data_url: dataUrl })
      }

      return fetch(
        `${config.apiUrl ?? ''}/api/quizes/save-question-image/${questionId}/`,
        requestOptions
      ).then(this.handleResponse)
    }
    saveQuestionAudio (questionId, dataUrl) {
      const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ data_url: dataUrl })
      }

      return fetch(
        `${config.apiUrl ?? ''}/api/quizes/save-question-audio/${questionId}/`,
        requestOptions
      ).then(this.handleResponse)
    }
}

export const quizService = new QuizService()
