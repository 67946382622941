import { faqQuestionService } from '../services'

export const faqQuestions = {
  namespaced: true,
  state: {
    byFaqSections: {}
  },
  actions: {
    getByFaqSectionId ({ commit }, faqSectionId) {
      commit('getByFaqSectionIdRequest', { faqSectionId })
      faqQuestionService.getByFaqSectionId(faqSectionId).then(
        questions =>
          commit('getByFaqSectionIdSuccess', { faqSectionId, questions }),
        error => commit('getByFaqSectionIdFailure', { faqSectionId, error })
      )
    },
    getById ({ commit }, id) {
      commit('getByIdRequest')
      faqQuestionService.getById(id).then(
        question => commit('getByIdSuccess', question),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, payload) {
      const faqSectionId = payload.faqSectionId
      commit('createRequest', payload)
      faqQuestionService.create(payload.faqSectionId, payload).then(
        question => {
          commit('createSuccess', { faqSectionId, question })
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      faqQuestionService.update(payload.faqSectionId, payload).then(
        question => {
          commit('updateSuccess', question)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    delete ({ dispatch, commit }, payload) {
      commit('deleteRequest', payload)
      const faqSectionId = payload.faqSectionId
      faqQuestionService
        .delete(payload.faqSectionId, payload.faqQuestionId)
        .then(
          payload => {
            commit('deleteSuccess', payload)
            dispatch('getByFaqSectionId', faqSectionId)
          },
          error => {
            commit('deleteFailure', error)
            dispatch('alert/error', error, { root: true })
          }
        )
    }
  },
  mutations: {
    getByFaqSectionIdRequest (state, payload) {
      if (!state.byFaqSections[payload.faqSectionId]) {
        state.byFaqSections[payload.faqSectionId] = []
      }
    },
    getByFaqSectionIdSuccess (state, payload) {
      for (const newQuestion of payload.questions) {
        let questionExists = false
        for (const existingQuestion of state.byFaqSections[
          payload.faqSectionId
        ]) {
          if (existingQuestion.id === newQuestion.id) {
            questionExists = true
            break
          }
        }
        if (!questionExists) {
          state.byFaqSections[payload.faqSectionId].push(newQuestion)
        }
      }
      const questionIndexesToDelete = []
      for (const existingQuestion of state.byFaqSections[
        payload.faqSectionId
      ]) {
        let questionExists = false
        for (const newQuestion of payload.questions) {
          if (existingQuestion.id === newQuestion.id) {
            questionExists = true
            break
          }
        }
      }
      for (const questionIndexToDelete of questionIndexesToDelete) {
        state.byFaqSections[payload.faqSectionId].splice(
          questionIndexToDelete,
          1
        )
      }
    },
    getByFaqSectionIdFailure (state, payload) {
      if (!state.byFaqSections[payload.faqSectionId]) {
        state.byFaqSections[payload.faqSectionId] = []
      }
    },
    getByIdRequest (state, formData) {},
    getByIdSuccess (state, question) {
      state.all = { question: question }
    },
    getByIdFailure (state, error) {},
    createRequest (state, formData) {},
    createSuccess (state, file) {},
    createFailure (state, error) {},
    updateRequest (state, data) {},
    updateSuccess (state, file) {},
    updateFailure (state, error) {},
    deleteRequest (state, orderId, itemId) {},
    deleteSuccess (state, orderId, itemId) {},
    deleteFailure (state, error) {}
  }
}
