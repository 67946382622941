import config from 'config'
import { ApiObjectService } from './api-object.service'
import { authHeader } from '../helpers'

class QuestionService extends ApiObjectService {
  namespace = 'quiz-questions'

  create (quizId, data) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-questions/`,
      requestOptions
    ).then(this.handleResponse)
  }

  getAll (quizId) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-questions/`,
      requestOptions
    ).then(this.handleResponse)
  }

  update (quizId, data) {
    const newData = Object.assign({}, data)
    delete newData.meta
    delete newData.parts
    const requestOptions = {
      method: 'PUT',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(newData)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-questions/${data.id}/`,
      requestOptions
    ).then(this.handleResponse)
  }

  delete (quizId, id) {
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-questions/${id}/`,
      requestOptions
    ).then(this.handleResponse)
  }
}

export const questionService = new QuestionService()
