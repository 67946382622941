import { questionService } from '../services'

export const questions = {
  namespaced: true,
  state: {
    byQuiz: {}
  },
  actions: {
    getByQuizId ({ commit }, quizId) {
      commit('getByQuizIdRequest', { quizId })
      questionService.getAll(quizId).then(
        questions => commit('getByQuizIdSuccess', { quizId, questions }),
        error => commit('getByQuizIdFailure', { quizId, error })
      )
    },
    getById ({ commit }, id) {
      commit('getByIdRequest')
      questionService.getById(id).then(
        question => commit('getByIdSuccess', question),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      questionService.create(payload.quizId, payload).then(
        question => {
          commit('createSuccess', question)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      questionService.update(payload.quizId, payload).then(
        question => {
          commit('updateSuccess', question)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    delete ({ dispatch, commit }, payload) {
      commit('deleteRequest', payload)
      const quizId = payload.quizId
      questionService.delete(payload.quizId, payload.quizQuestionId).then(
        payload => {
          commit('deleteSuccess', payload)
          dispatch('getByQuizId', quizId)
        },
        error => {
          commit('deleteFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getByQuizIdRequest (state, payload) {
      if (!state.byQuiz[payload.quizId]) {
        state.byQuiz[payload.quizId] = []
      }
    },
    getByQuizIdSuccess (state, payload) {
      for (const newQuestion of payload.questions) {
        let questionExists = false
        for (const existingQuestion of state.byQuiz[payload.quizId]) {
          if (existingQuestion.id === newQuestion.id) {
            questionExists = true
            break
          }
        }
        if (!questionExists) {
          state.byQuiz[payload.quizId].push(newQuestion)
        }
      }
      const questionIndexesToDelete = []
      for (const existingQuestion of state.byQuiz[payload.quizId]) {
        let questionExists = false
        for (const newQuestion of payload.questions) {
          if (existingQuestion.id === newQuestion.id) {
            questionExists = true
            break
          }
        }
      }
      for (const questionIndexToDelete of questionIndexesToDelete) {
        state.byQuiz[payload.quizId].splice(questionIndexToDelete, 1)
      }
    },
    getByQuizIdFailure (state, payload) {
      if (!state.byQuiz[payload.quizId]) {
        state.byQuiz[payload.quizId] = []
      }
    },
    getByIdRequest (state, formData) {},
    getByIdSuccess (state, question) {
      state.all = { question: question }
    },
    getByIdFailure (state, error) {},
    createRequest (state, formData) {},
    createSuccess (state, file) {},
    createFailure (state, error) {},
    updateRequest (state, data) {},
    updateSuccess (state, file) {},
    updateFailure (state, error) {},
    deleteRequest (state, orderId, itemId) {},
    deleteSuccess (state, orderId, itemId) {},
    deleteFailure (state, error) {},
  }
}
