import Vue from 'vue'
import Router from 'vue-router'
import { authService } from '../services/auth.service'

const TheContainer = () => import('@/containers/TheContainer')
const QuizForm = () => import('@/views/QuizForm')
const QuizesList = () => import('@/views/QuizesList')
const QuestionForm = () => import('@/views/QuestionForm')
const AnswerForm = () => import('@/views/AnswerForm')
const QuizResultsList = () => import('@/views/QuizResultsList')
const QuizResultForm = () => import('@/views/QuizResultForm')
const PredictionsList = () => import('@/views/PredictionsList')
const PredictionForm = () => import('@/views/PredictionForm')
const ZodiacSignsList = () => import('@/views/ZodiacSignsList')
const ZodiacSignForm = () => import('@/views/ZodiacSignForm')
const ParticipantsList = () => import('@/views/ParticipantsList')
const ParticipantsStats = () => import('@/views/ParticipantsStats')
const ParticipantForm = () => import('@/views/ParticipantForm')
const FaqsList = () => import('@/views/FaqsList')
const FaqForm = () => import('@/views/FaqForm')
const TotalAnswerCountStats = () => import('@/views/TotalAnswerCountStats')
const TigerFactsList = () => import('@/views/TigerFactsList')
const TigerFactForm = () => import('@/views/TigerFactForm')
const RabbitAdvicesList = () => import('@/views/RabbitAdvicesList')
const RabbitAdviceForm = () => import('@/views/RabbitAdviceForm')
const StaticList = () => import('@/views/StaticList')

const LoginForm = () => import('@/views/LoginForm')

Vue.use(Router)

export const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, from, next) => {
  const authPages = ['/login']
  const authRequired = !authPages.includes(to.path)
  const loggedIn = authService.isLoggedIn()
  if (authRequired && !loggedIn) {
    return next('/login')
  } else if (!authRequired && loggedIn) {
    return next('/')
  }

  return next()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/quizes',
      component: TheContainer,
      children: [
        {
          path: 'quizes',
          name: 'quizes',
          component: QuizesList
        },
        {
          path: '/quizes/create',
          name: 'quiz-create',
          component: QuizForm
        },
        {
          path: '/quizes/:id',
          name: 'quiz-edit',
          component: QuizForm
        },
        {
          path: '/questions/create',
          name: 'question-create',
          component: QuestionForm
        },
        {
          path: '/questions/:id',
          name: 'question-update',
          component: QuestionForm
        },
        {
          path: '/answers/create',
          name: 'answer-create',
          component: AnswerForm
        },
        {
          path: '/answers/:id',
          name: 'answer-update',
          component: AnswerForm
        },
        {
          path: '/results/:quizId',
          name: 'results',
          component: QuizResultsList
        },
        {
          path: '/results/:quizId/create',
          name: 'result-create',
          component: QuizResultForm
        },
        {
          path: '/results/:quizId/edit/:id',
          name: 'result-edit',
          component: QuizResultForm
        },
        {
          path: '/predictions',
          name: 'predictions',
          component: PredictionsList
        },
        {
          path: '/predictions/create',
          name: 'prediction-create',
          component: PredictionForm
        },
        {
          path: '/predictions/:id',
          name: 'prediction-edit',
          component: PredictionForm
        },
        {
          path: '/zodiac-signs',
          name: 'zodiac-signs',
          component: ZodiacSignsList
        },
        {
          path: '/zodiac-signs/create',
          name: 'zodiac-sign-create',
          component: ZodiacSignForm
        },
        {
          path: '/zodiac-signs/:id',
          name: 'zodiac-sign-edit',
          component: ZodiacSignForm
        },
        {
          path: '/participants/stats',
          name: 'participant-stats',
          component: ParticipantsStats
        },
        {
          path: '/participants/:id',
          name: 'participant',
          component: ParticipantForm
        },
        {
          path: 'faqs',
          name: 'faqs',
          component: FaqsList
        },
        {
          path: '/faqs/create',
          name: 'faq-create',
          component: FaqForm
        },
        {
          path: '/faqs/:id',
          name: 'faq-edit',
          component: FaqForm
        },
        {
          path: '/reporting/total-answer-count',
          name: 'total-answer-count-stats',
          component: TotalAnswerCountStats
        },
        {
          path: '/tiger-facts',
          name: 'tiger-facts',
          component: TigerFactsList
        },
        {
          path: '/tiger-facts/create',
          name: 'tiger-fact-create',
          component: TigerFactForm
        },
        {
          path: '/tiger-facts/:id',
          name: 'tiger-fact-edit',
          component: TigerFactForm
        },
        {
          path: '/rabbit-advices',
          name: 'rabbit-advices',
          component: RabbitAdvicesList
        },
        {
          path: '/rabbit-advices/create',
          name: 'rabbit-advice-create',
          component: RabbitAdviceForm
        },
        {
          path: '/rabbit-advices/:id',
          name: 'rabbit-advice-edit',
          component: RabbitAdviceForm
        },
        {
          path: '/static/',
          name: 'static',
          component: StaticList
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: LoginForm
    }
  ]
}
