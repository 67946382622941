import config from 'config'
import { ApiObjectService } from './api-object.service'
import { authHeader } from '../helpers'

class PredictionService extends ApiObjectService {
    namespace = 'predictions'

    saveImage (id, dataUrl) {
      const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ data_url: dataUrl })
      }

      return fetch(
        `${config.apiUrl ?? ''}/api/predictions/${id}/save-image/`,
        requestOptions
      ).then(this.handleResponse)
    }
    saveShareImage (id, dataUrl) {
      const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ data_url: dataUrl })
      }

      return fetch(
        `${config.apiUrl ?? ''}/api/predictions/${id}/save-share-image/`,
        requestOptions
      ).then(this.handleResponse)
    }
}

export const predictionService = new PredictionService()
