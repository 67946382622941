import { router } from '../router/index'
import { resultService } from '../services'

export const results = {
  namespaced: true,
  state: {
    byQuiz: {}
  },
  actions: {
    getByQuizId ({ commit }, payload) {
      const quizId = payload
      commit('getByQuizIdRequest', { quizId })
      resultService.getByQuizId(quizId).then(
        results => commit('getByQuizIdSuccess', { quizId, results }),
        error => commit('getByQuizIdFailure', { quizId, error })
      )
    },
    getById ({ commit }, payload) {
      commit('getByIdRequest')
      resultService.getById(payload.quizId, payload.id).then(
        result => commit('getByIdSuccess', result),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      resultService.create(payload.quizId, payload).then(
        result => {
          commit('createSuccess', result)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      resultService.update(payload.quizId, payload).then(
        result => {
          commit('updateSuccess', result)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    delete ({ dispatch, commit }, payload) {
      commit('deleteRequest', payload)
      const quizId = payload.quizId
      resultService.delete(payload.quizId, payload.quizResultId).then(
        payload => {
          commit('deleteSuccess', payload)
          dispatch('getByQuizId', quizId)
        },
        error => {
          commit('deleteFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveImage ({ dispatch, commit }, payload) {
      commit('saveImageRequest', payload)
      resultService.saveImage(payload.quizId, payload.id, payload.dataUrl).then(
        payload => {
          commit('saveImageSuccess', payload)
        },
        error => {
          commit('saveImageFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    savePoster ({ dispatch, commit }, payload) {
      commit('savePosterRequest', payload)
      resultService.savePoster(payload.quizId, payload.id, payload.dataUrl).then(
        payload => {
          commit('savePosterSuccess', payload)
        },
        error => {
          commit('savePosterFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveShareImage ({ dispatch, commit }, payload) {
      commit('saveShareImageRequest', payload)
      resultService.saveShareImage(payload.quizId, payload.id, payload.dataUrl).then(
        payload => {
          commit('saveShareImageSuccess', payload)
        },
        error => {
          commit('saveShareImageFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
  },
  mutations: {
    getByQuizIdRequest (state, payload) {
      if (!state.byQuiz[payload.quizId]) {
        state.byQuiz[payload.quizId] = []
      }
    },
    getByQuizIdSuccess (state, payload) {
      for (const newResult of payload.results) {
        let resultExists = false
        for (const existingAnswer of state.byQuiz[payload.quizId]) {
          if (existingAnswer.id === newResult.id) {
            resultExists = true
            break
          }
        }
        if (!resultExists) {
          state.byQuiz[payload.quizId].push(newResult)
        }
      }
      const resultIndexesToDelete = []
      for (const existingAnswer of state.byQuiz[payload.quizId]) {
        let resultExists = false
        for (const newResult of payload.results) {
          if (existingAnswer.id === newResult.id) {
            resultExists = true
            break
          }
        }
      }
      for (const resultIndexToDelete of resultIndexesToDelete) {
        state.byQuiz[payload.quizId].splice(resultIndexToDelete, 1)
      }
    },
    getByQuizIdFailure (state, payload) {
      if (!state.byQuiz[payload.quizId]) {
        state.byQuiz[payload.quizId] = []
      }
    },
    getByIdRequest (state, payload) {},
    getByIdSuccess (state, result) {},
    getByIdFailure (state, error) {},
    createRequest (state, payload) {},
    createSuccess (state, payload) {},
    createFailure (state, error) {},
    updateRequest (state, payload) {},
    updateSuccess (state, payload) {},
    updateFailure (state, error) {},
    deleteRequest (state, payload) {},
    deleteSuccess (state, payload) {},
    deleteFailure (state, error) {},
    saveImageRequest (state, payload) {},
    saveImageSuccess (state, payload) {},
    saveImageFailure (state, error) {},
    savePosterRequest (state, payload) {},
    savePosterSuccess (state, payload) {},
    savePosterFailure (state, error) {},
    saveShareImageRequest (state, payload) {},
    saveShareImageSuccess (state, payload) {},
    saveShareImageFailure (state, error) {}
  }
}
