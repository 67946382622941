import config from 'config'
import { ApiObjectService } from './api-object.service'
import { authHeader } from '../helpers'
import _nav from '../containers/_nav'

function downloadBlob (blob, filename) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
      a.remove()
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
}

class ReportingService extends ApiObjectService {
  namespace = 'reporting'

  getTotalAnswerCountJSON () {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/total-answer-report/json`,
      requestOptions
    ).then(this.handleResponse)
  }

  getTotalAnswerCountXLSX () {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/total-answer-report/xlsx`,
      requestOptions
    )
      .then(response => response.blob())
      .then(blob => {
        downloadBlob(blob, 'total-answer-report.xlsx')
      })
  }

  getAnswerReportXLSX (questionId, answerId) {
    //const url =
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/answer-report/xlsx?question_id=${questionId}&answer_id=${answerId}`,
      requestOptions
    )
      .then(response => response.blob())
      .then(blob => {
        downloadBlob(blob, `answer-report-${questionId}-${answerId}.xlsx`)
      })
  }

  getZodiacSignsReportXLSX () {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/zodiac-signs-report/xlsx`,
      requestOptions
    )
      .then(response => response.blob())
      .then(blob => {
        downloadBlob(blob, `zodiac-signs-report.xlsx`)
      })
  }

  getPersonTypesReportXLSX () {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl}/api/${this.namespace}/person-types-report/xlsx`,
      requestOptions
    )
      .then(response => response.blob())
      .then(blob => {
        downloadBlob(blob, `person-types-report.xlsx`)
      })
  }
}

export const reportingService = new ReportingService()
