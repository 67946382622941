import config from 'config'
import { ApiObjectService } from './api-object.service'
import { authHeader } from '../helpers'

class RabbitAdvicesService extends ApiObjectService {
    namespace = 'rabbit-advices'
    saveAdviceImage (adviceId, dataUrl) {
      const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ data_url: dataUrl })
      }

      return fetch(
        `${config.apiUrl ?? ''}/api/rabbit-advices/save-advice-image/${adviceId}/`,
        requestOptions
      ).then(this.handleResponse)
    }
}

export const rabbitAdvicesService = new RabbitAdvicesService()
