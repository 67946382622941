import { quizService } from '../services'

export const quizes = {
  namespaced: true,
  state: {
    all: [],
    byId: {}
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      quizService.getAll().then(
        quizes => commit('getAllSuccess', quizes),
        error => commit('getAllFailure', error)
      )
    },
    getById ({ commit }, id) {
      commit('getByIdRequest')
      quizService.getById(id).then(
        quiz => commit('getByIdSuccess', quiz),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, formData) {
      commit('createRequest', formData)
      quizService.create(formData).then(
        quiz => {
          commit('createSuccess', quiz)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, data) {
      commit('updateRequest', data)
      quizService.update(data).then(
        quiz => {
          commit('updateSuccess', quiz)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveQuestionImage ({ dispatch, commit }, payload) {
      commit('saveQuestionImageRequest', payload)
      quizService.saveQuestionImage(payload.id, payload.dataUrl).then(
        payload => {
          commit('saveQuestionImageSuccess', payload)
        },
        error => {
          commit('saveQuestionImageFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveQuestionAudio ({ dispatch, commit }, payload) {
      commit('saveQuestionAudioRequest', payload)
      quizService.saveQuestionAudio(payload.id, payload.dataUrl).then(
        payload => {
          commit('saveQuestionAudioSuccess', payload)
        },
        error => {
          commit('saveQuestionAudioFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getAllRequest (state) {},
    getAllSuccess (state, quizes) {
      state.all = quizes
      for (const quiz of quizes) {
        state.byId[quiz.id] = quiz
      }
    },
    getAllFailure (state, error) {},
    getByIdRequest (state, formData) {},
    getByIdSuccess (state, quiz) {
      state.byId[quiz.id] = quiz
    },
    getByIdFailure (state, error) {},
    createRequest (state, formData) {},
    createSuccess (state, file) {},
    createFailure (state, error) {},
    updateRequest (state, data) {},
    updateSuccess (state, file) {},
    updateFailure (state, error) {},
    saveQuestionImageRequest (state, data) {},
    saveQuestionImageSuccess (state, file) {},
    saveQuestionImageFailure (state, error) {},
    saveQuestionAudioRequest (state, data) {},
    saveQuestionAudioSuccess (state, file) {},
    saveQuestionAudioFailure (state, error) {}
  }
}
