import { router } from '../router/index'
import { participantService } from '../services'

export const participants = {
  namespaced: true,
  state: {
    all: {},
    stats_json: {}
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      participantService.getAll().then(
        participants => commit('getAllSuccess', participants),
        error => commit('getAllFailure', error)
      )
    },
    getById ({ commit }, id) {
      commit('getByIdRequest')
      participantService.getById(id).then(
        participant => commit('getByIdSuccess', participant),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, formData) {
      commit('createRequest', formData)
      participantService.create(formData).then(
        file => {
          commit('createSuccess', file)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, data) {
      commit('updateRequest', data)
      participantService.update(data).then(
        file => {
          commit('updateSuccess', file)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    getStatsJson ({ commit }) {
      commit('getStatsJsonRequest')
      participantService.getStatsJson().then(
        payload => commit('getStatsJsonSuccess', payload),
        error => commit('getStatsJsonFailure', error)
      )
    },
  },
  mutations: {
    getAllRequest (state) {
      state.all = { loading: true }
    },
    getAllSuccess (state, participants) {
      state.all = { items: participants }
    },
    getAllFailure (state, error) {
      state.all = { error }
    },
    getByIdRequest (state, formData) {},
    getByIdSuccess (state, participant) {
      state.all = { participant: participant }
    },
    getByIdFailure (state, error) {},
    createRequest (state, formData) {},
    createSuccess (state, file) {},
    createFailure (state, error) {},
    updateRequest (state, data) {},
    updateSuccess (state, file) {},
    updateFailure (state, error) {},
    getStatsJsonRequest (state, payload) {},
    getStatsJsonSuccess (state, payload) {
      state.stats_json = payload
    },
    getStatsJsonFailure (state, error) {},
  }
}
