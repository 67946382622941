import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from './alert.module'
import { account } from './account.module'
import { quizes } from './quizes.module'
import { questions } from './questions.module'
import { answers } from './answers.module'
import { predictions } from './predictions.module'
import { results } from './results.module'
import { participants } from './participants.module'
import { personTypes } from './person-types.module'
import { zodiacSigns } from './zodiac-signs.module'
import { faqs } from './faqs.module'
import { faqQuestions } from './faq-questions.module'
import { faqSections } from './faq-sections.module'
import { reporting } from './reporting.module'
import { tigerFacts } from './tiger-facts.module'
import { rabbitAdvices } from './rabbit-advices.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alert,
    account,
    quizes,
    questions,
    answers,
    predictions,
    results,
    participants,
    personTypes,
    zodiacSigns,
    faqs,
    faqQuestions,
    faqSections,
    reporting,
    tigerFacts,
    rabbitAdvices
  }
})
