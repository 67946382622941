import { rabbitAdvicesService } from '../services'

export const rabbitAdvices = {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      rabbitAdvicesService.getAll().then(
        rabbitAdvices => commit('getAllSuccess', rabbitAdvices),
        error => commit('getAllFailure', error)
      )
    },
    getById ({ commit }, id) {
      commit('getByIdRequest')
      rabbitAdvicesService.getById(id).then(
        rabbitAdvice => commit('getByIdSuccess', rabbitAdvice),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      rabbitAdvicesService.create(payload).then(
        rabbitAdvice => {
          commit('createSuccess', rabbitAdvice)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      rabbitAdvicesService.update(payload).then(
        rabbitAdvice => {
          commit('updateSuccess', rabbitAdvice)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveAdviceImage ({ dispatch, commit }, payload) {
      commit('saveAdviceImageRequest', payload)
      rabbitAdvicesService.saveAdviceImage(payload.id, payload.dataUrl).then(
        payload => {
          commit('saveAdviceImageSuccess', payload)
        },
        error => {
          commit('saveAdviceImageFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
  },
  mutations: {
    getAllRequest (state) {},
    getAllSuccess (state, rabbitAdvices) {
      state.all = rabbitAdvices
    },
    getAllFailure (state, error) {},
    getByIdRequest (state, payload) {},
    getByIdSuccess (state, payload) {
      const rabbitAdvice = payload
      const newAllRabbitAdvices = []
      let isNew = true
      for (const currentRabbitAdvice of state.all) {
        if (currentRabbitAdvice.id === rabbitAdvice.id) {
          newAllRabbitAdvices.push(rabbitAdvice)
          isNew = false
        } else {
          newAllRabbitAdvices.push(currentRabbitAdvice)
        }
      }
      state.all = newAllRabbitAdvices
    },
    getByIdFailure (state, error) {},
    createRequest (state, payload) {},
    createSuccess (state, payload) {},
    createFailure (state, error) {},
    updateRequest (state, payload) {},
    updateSuccess (state, payload) {},
    updateFailure (state, error) {},
    saveAdviceImageRequest (state, payload) {},
    saveAdviceImageSuccess (state, payload) {},
    saveAdviceImageFailure (state, error) {},
  }
}
