import config from 'config'
import { ApiObjectService } from './api-object.service'
import { authHeader } from '../helpers'

class QuizResultService extends ApiObjectService {
  namespace = 'quiz-results'

  create (quizId, data) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-results/`,
      requestOptions
    ).then(this.handleResponse)
  }

  getByQuizId (quizId) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-results/`,
      requestOptions
    ).then(this.handleResponse)
  }

  getById (quizId, id) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-results/${id}`,
      requestOptions
    ).then(this.handleResponse)
  }

  update (quizId, data) {
    const newData = Object.assign({}, data)
    const requestOptions = {
      method: 'PUT',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(newData)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-results/${data.id}/`,
      requestOptions
    ).then(this.handleResponse)
  }

  delete (quizId, id) {
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-results/${id}/`,
      requestOptions
    ).then(this.handleResponse)
  }
  saveImage (quizId, id, dataUrl) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ data_url: dataUrl })
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-results/${id}/save-image/`,
      requestOptions
    ).then(this.handleResponse)
  }
  savePoster (quizId, id, dataUrl) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ data_url: dataUrl })
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-results/${id}/save-poster/`,
      requestOptions
    ).then(this.handleResponse)
  }
  saveShareImage (quizId, id, dataUrl) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ data_url: dataUrl })
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/quizes/${quizId}/quiz-results/${id}/save-share-image/`,
      requestOptions
    ).then(this.handleResponse)
  }
}

export const resultService = new QuizResultService()
