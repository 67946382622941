export function createModule (service) {
  return {
    namespaced: true,
    state: {
      all: [],
      byId: {}
    },
    actions: {
      getAll ({ commit }) {
        commit('getAllRequest')
        service.getAll().then(
          objects => commit('getAllSuccess', objects),
          error => commit('getAllFailure', error)
        )
      },
      getById ({ commit }, id) {
        commit('getByIdRequest')
        service.getById(id).then(
          object => commit('getByIdSuccess', object),
          error => commit('getByIdFailure', error)
        )
      },
      create ({ dispatch, commit }, payload) {
        commit('createRequest', payload)
        service.create(payload).then(
          object => {
            commit('createSuccess', object)
          },
          error => {
            commit('createFailure', error)
            dispatch('alert/error', error, { root: true })
          }
        )
      },
      update ({ dispatch, commit }, payload) {
        commit('updateRequest', payload)
        service.update(payload).then(
          object => {
            commit('updateSuccess', object)
          },
          error => {
            commit('updateFailure', error)
            dispatch('alert/error', error, { root: true })
          }
        )
      }
    },
    mutations: {
      getAllRequest (state) {},
      getAllSuccess (state, objectes) {
        state.all = objectes
        for (const object of objectes) {
          state.byId[object.id] = object
        }
      },
      getAllFailure (state, error) {},
      getByIdRequest (state, payload) {},
      getByIdSuccess (state, object) {
        state.byId[object.id] = object
      },
      getByIdFailure (state, error) {},
      createRequest (state, payload) {},
      createSuccess (state, payload) {},
      createFailure (state, error) {},
      updateRequest (state, payload) {},
      updateSuccess (state, payload) {},
      updateFailure (state, error) {}
    }
  }
}
