import { tigerFactsService } from '../services'

export const tigerFacts = {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      tigerFactsService.getAll().then(
        tigerFacts => commit('getAllSuccess', tigerFacts),
        error => commit('getAllFailure', error)
      )
    },
    getById ({ commit }, id) {
      commit('getByIdRequest')
      tigerFactsService.getById(id).then(
        tigerFact => commit('getByIdSuccess', tigerFact),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      tigerFactsService.create(payload).then(
        tigerFact => {
          commit('createSuccess', tigerFact)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      tigerFactsService.update(payload).then(
        tigerFact => {
          commit('updateSuccess', tigerFact)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveImage ({ dispatch, commit }, payload) {
      commit('saveImageRequest', payload)
      tigerFactsService.saveImage(payload.id, payload.dataUrl).then(
        payload => {
          commit('saveImageSuccess', payload)
        },
        error => {
          commit('saveImageFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveShareImage ({ dispatch, commit }, payload) {
      commit('saveShareImageRequest', payload)
      tigerFactsService.saveShareImage(payload.id, payload.dataUrl).then(
        payload => {
          commit('saveShareImageSuccess', payload)
        },
        error => {
          commit('saveShareImageFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getAllRequest (state) {},
    getAllSuccess (state, tigerFacts) {
      state.all = tigerFacts
    },
    getAllFailure (state, error) {},
    getByIdRequest (state, payload) {},
    getByIdSuccess (state, payload) {
      const tigerFact = payload
      const newAllTigerFacts = []
      let isNew = true
      for (const currentTigerFact of state.all) {
        if (currentTigerFact.id === tigerFact.id) {
          newAllTigerFacts.push(tigerFact)
          isNew = false
        } else {
          newAllTigerFacts.push(currentTigerFact)
        }
      }
      state.all = newAllTigerFacts
    },
    getByIdFailure (state, error) {},
    createRequest (state, payload) {},
    createSuccess (state, payload) {},
    createFailure (state, error) {},
    updateRequest (state, payload) {},
    updateSuccess (state, payload) {},
    updateFailure (state, error) {},
    saveImageRequest (state, payload) {},
    saveImageSuccess (state, payload) {},
    saveImageFailure (state, error) {},
    saveShareImageRequest (state, payload) {},
    saveShareImageSuccess (state, payload) {},
    saveShareImageFailure (state, error) {}
  }
}
