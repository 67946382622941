import config from 'config'
import { ApiObjectService } from './api-object.service'
import { authHeader } from '../helpers'

class FaqSectionService extends ApiObjectService {
  namespace = 'faq-sections'

  create (faqId, data) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/faqs/${faqId}/faq-sections/`,
      requestOptions
    ).then(this.handleResponse)
  }

  getByFaqId (faqId) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/faqs/${faqId}/faq-sections/`,
      requestOptions
    ).then(this.handleResponse)
  }

  update (faqId, data) {
    const newData = Object.assign({}, data)
    delete newData.meta
    delete newData.parts
    const requestOptions = {
      method: 'PUT',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(newData)
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/faqs/${faqId}/faq-sections/${data.id}/`,
      requestOptions
    ).then(this.handleResponse)
  }

  delete (faqId, id) {
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
    }

    return fetch(
      `${config.apiUrl ?? ''}/api/faqs/${faqId}/faq-sections/${id}/`,
      requestOptions
    ).then(this.handleResponse)
  }
}

export const faqSectionService = new FaqSectionService()
