<template>
  <router-view :project-id="projectId" ></router-view>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      projectId: 1
    }
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
