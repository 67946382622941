import { predictionService } from '../services'

export const predictions = {
  namespaced: true,
  state: {
    all: []
  },
  actions: {
    getAll ({ commit }) {
      commit('getAllRequest')
      predictionService.getAll().then(
        predictions => commit('getAllSuccess', predictions),
        error => commit('getAllFailure', error)
      )
    },
    getById ({ commit }, id) {
      commit('getByIdRequest')
      predictionService.getById(id).then(
        prediction => commit('getByIdSuccess', prediction),
        error => commit('getByIdFailure', error)
      )
    },
    create ({ dispatch, commit }, payload) {
      commit('createRequest', payload)
      predictionService.create(payload).then(
        prediction => {
          commit('createSuccess', prediction)
        },
        error => {
          commit('createFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    update ({ dispatch, commit }, payload) {
      commit('updateRequest', payload)
      predictionService.update(payload).then(
        prediction => {
          commit('updateSuccess', prediction)
        },
        error => {
          commit('updateFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveImage ({ dispatch, commit }, payload) {
      commit('saveImageRequest', payload)
      predictionService.saveImage(payload.id, payload.dataUrl).then(
        payload => {
          commit('saveImageSuccess', payload)
        },
        error => {
          commit('saveImageFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    },
    saveShareImage ({ dispatch, commit }, payload) {
      commit('saveShareImageRequest', payload)
      predictionService.saveShareImage(payload.id, payload.dataUrl).then(
        payload => {
          commit('saveShareImageSuccess', payload)
        },
        error => {
          commit('saveShareImageFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
    }
  },
  mutations: {
    getAllRequest (state) {},
    getAllSuccess (state, predictions) {
      state.all = predictions
    },
    getAllFailure (state, error) {},
    getByIdRequest (state, payload) {},
    getByIdSuccess (state, payload) {
      const prediction = payload
      const newAllPredictions = []
      let isNew = true
      for (const currentPrediction of state.all) {
        if (currentPrediction.id === prediction.id) {
          newAllPredictions.push(prediction)
          isNew = false
        } else {
          newAllPredictions.push(currentPrediction)
        }
      }
      state.all = newAllPredictions
    },
    getByIdFailure (state, error) {},
    createRequest (state, payload) {},
    createSuccess (state, payload) {},
    createFailure (state, error) {},
    updateRequest (state, payload) {},
    updateSuccess (state, payload) {},
    updateFailure (state, error) {},
    saveImageRequest (state, payload) {},
    saveImageSuccess (state, payload) {},
    saveImageFailure (state, error) {},
    saveShareImageRequest (state, payload) {},
    saveShareImageSuccess (state, payload) {},
    saveShareImageFailure (state, error) {}
  }
}
