import config from 'config'
import { authHeader } from '../helpers'
import { ApiObjectService } from './api-object.service'

class ParticipantService extends ApiObjectService {
    namespace = 'participants'

    getStatsJson () {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }

      return fetch(`${config.apiUrl ?? ''}/api/participants/stats.json`, requestOptions).then(
        this.handleResponse
      )
    }
}

export const participantService = new ParticipantService()
