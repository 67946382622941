export function makeCoreUICSelectList(labelProperty, valueProperty, dataObjects, addZeroValue = true) {
  const result = [];
  if (addZeroValue) {
    result.push({ value: 0, label: "Выберите значение" });
  }
  for (const dataObject of dataObjects) {
    result.push({
      value: dataObject[valueProperty],
      label: dataObject[labelProperty],
    });
  }
  return result;
}
