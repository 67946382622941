import { reportingService } from '../services'
import { createModule } from './module-factory'

export const reporting = {
  namespaced: true,
  state: {
    totalAnswerCount: []
  },
  actions: {
    getTotalAnswerCountJSON ({ commit }, payload) {
      commit('getTotalAnswerCountJSONRequest', { payload })
      reportingService.getTotalAnswerCountJSON().then(
        payload => commit('getTotalAnswerCountJSONSuccess', payload),
        error => commit('getTotalAnswerCountJSONFailure', { payload, error })
      )
    },
  },
  mutations: {
    getTotalAnswerCountJSONRequest (state, payload) {
      state.totalAnswerCount = payload
    },
    getTotalAnswerCountJSONSuccess (state, result) {},
    getTotalAnswerCountJSONFailure (state, error) {}
  }
}
